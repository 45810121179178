export default class Helpers {
  requiredFormFields(value) {
    return [(v) => !!v || "Please enter your " + value];
  }
  nairaSign() {
    return "₦";
  }
  formatAmount(value) {
    if (value) {
      return (
        this.nairaSign() +
        Number(value).toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      );
    }
    return this.nairaSign() + "0.00";
  }
  formatDate(value) {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    return new Date(value).toLocaleDateString("en-GB", options);
  }
  logConsole(val) {
    if (process.env.NODE_ENV !== "production") {
      console.log(val);
    }
  }
  formatToken(string = null) {
    return string ? string.match(/.{1,4}/g).join("-") : null;
  }
}
